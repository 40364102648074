import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Navbar, Nav, Card, Form, Button } from 'react-bootstrap';
import './index.css';
import 'intersection-observer'; // Intersection Observer polyfill

const App = () => {
  const [visible, setVisible] = useState({});
  const observer = useRef(null);

  const handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setVisible(prevVisible => ({
          ...prevVisible,
          [entry.target.id]: true
        }));
        observer.current.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.2
    });

    document.querySelectorAll('.fade-in').forEach(el => {
      observer.current.observe(el);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" className="fixed-top">
        <Container>
          <Navbar.Brand href="#home" className="font-weight-bold text-primary">My Resume</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#skills">Skills</Nav.Link>
              <Nav.Link href="#projects">Projects</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="my-5 pt-5">
        <div className="header text-center mb-5">
          <Image className="profile-pic mb-3" src="path/to/profile-pic.jpg" roundedCircle />
          <h1 className="my-3 text-primary">Qobiljonov Muhammadjon</h1>
          <h2 className="text-secondary">Junior Full Stack Developer</h2>
        </div>

        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={`section-card border-primary rounded-lg p-4 mb-4 hover-effect shadow-sm fade-in ${visible['about'] ? 'visible' : ''}`} id="about">
              <Card.Body>
                <Card.Title className="section-title mb-3 text-primary">About Me</Card.Title>
                <Card.Text className="info-item mb-2">
                  <strong>Qisqacha ma'lumot:</strong>
                  <p className="info-text">Mening ismim Muhammadjon yoshim 20 da 2 yildan ber It ga qiziqib kelaman </p>
                </Card.Text>
                <Card.Text className="info-item mb-2">
                  <strong>Tug'ilgan kun:</strong>
                  <p className="info-text">14-Noyabr, 2004</p>
                </Card.Text>
                <Card.Text className="info-item mb-2">
                  <strong>Yashash joyi:</strong>
                  <p className="info-text">Samarqand, O'zbekiston</p>
                </Card.Text>
                <Card.Text className="info-item mb-2">
                  <strong>Email:</strong>
                  <p className="info-text">shuxrat0166@gmail.com</p>
                </Card.Text>
                <Card.Text className="info-item mb-2">
                  <strong>Telefon:</strong>
                  <p className="info-text">+998 93 333 19 84</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={`section-card border-success rounded-lg p-4 mb-4 hover-effect shadow-sm fade-in ${visible['skills'] ? 'visible' : ''}`} id="skills">
              <Card.Body>
                <Card.Title className="section-title mb-3 text-success">Skills</Card.Title>
                <ul className="list-unstyled">
                  <li className="info-item">PHP</li>
                  <li className="info-item">JavaScript</li>
                  <li className="info-item">Symfony</li>
                  <li className="info-item">Git</li>
                  <li className="info-item">React Js</li>
                  <li className="info-item">Doker</li>
                  <li className="info-item">MySQL</li>
                  <li className="info-item">Bootstrap</li>
                  <li className="info-item">Tailwand css</li>
                  <li className="info-item">Vue Js</li>
                  <li className="info-item">Material Ui</li>
                  <li className="info-item">React Redux</li>
                  <li className="info-item">React Toolkit</li>
                </ul>
                <Card.Title className="section-title mt-4 text-success">Education</Card.Title>
                <Card.Text className="info-text">O'quv muassasasi nomi - <a href='https://kadirov.dev' >Kadirov.dev</a></Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={`section-card border-info rounded-lg p-4 mb-4 hover-effect shadow-sm fade-in ${visible['projects'] ? 'visible' : ''}`} id="projects">
              <Card.Body>
                <Card.Title className="section-title mb-3 text-info">Projects</Card.Title>
                <Card.Text className="info-item mb-2">
                  <p className="info-text">empty for now - <a href="https://example.com" className="text-info">Link</a></p>
                </Card.Text>
                <Card.Text className="info-item mb-2">
                  <p className="info-text">empty for now - <a href="https://example.com" className="text-info">Link</a></p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={`section-card border-warning rounded-lg p-4 mb-4 hover-effect shadow-sm fade-in ${visible['hobbies'] ? 'visible' : ''}`} id="hobbies">
              <Card.Body>
                <Card.Title className="section-title mb-3 text-warning">Hobbies</Card.Title>
                <Card.Text className="info-text">Reading Books, - GamePlay</Card.Text>
                <Card.Title className="section-title mt-4 text-warning">Character</Card.Title>
                <Card.Text className="info-text">Tez ishlashni yoqtiraman</Card.Text>
                <Card.Title className="section-title mt-4 text-warning">Salary Expectation</Card.Title>
                <Card.Text className="info-text">Oylik: 500$</Card.Text>
                <Card.Title className="section-title mt-4 text-warning">Languages</Card.Title>
                <Card.Text className="info-text">Inglizcha - Yaxshidan Pastroq</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={`section-card border-danger rounded-lg p-4 mb-4 hover-effect shadow-sm fade-in ${visible['contact'] ? 'visible' : ''}`} id="contact">
              <Card.Body>
                <Card.Title className="section-title mb-3 text-danger">Contact</Card.Title>
                <Form>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>
                  <Form.Group controlId="formBasicMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Your message" />
                  </Form.Group>
                  <Button variant="danger" type="submit">
                    Send
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <footer className="bg-dark text-white text-center py-4 mt-5">
        <Container>
          <p className="mb-0">&copy; 2024 My Resume</p>
        </Container>
      </footer>
    </div>
  );
};

export default App;
